<template>
    <div class="wraper">
        <Header :isShow="isShow" />
        <!-- 轮播图 -->
        <div class="banner" >
            <div class="swiper-container">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="(item, index) in list" :key="index" @click="gotoPage(item.link)">
                        <img :src="imgUrl + item.image" />
                        <div class="content_box">
                            <div class="content max_small_box" data-aos="fade-up">
                                <p class="title">{{item.title}}</p>
                                <p class="desc">{{item.desc}}</p>
                                <p v-if="item.link==='' || item.link===null"></p>
                                <p class="more" v-else>了解详情</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="swiper-button-next"></div>
                <div class="swiper-button-prev"></div>
                <div class="swiper-pagination"></div>
            </div>
        </div>

        <div class="banner banner_two" >
            <div class="swiper-container-two">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="(item, index) in mobile" :key="index" @click="gotoPage(item.link)">
                        <img :src="imgUrl + item.image" />
                        <div class="content_box">
                            <div class="content max_small_box" data-aos="fade-up">
                                <p class="title">{{item.title}}</p>
                                <p class="desc">{{item.desc}}</p>
                                <p class="more">了解详情</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="swiper-button-next-two"></div>
                <div class="swiper-button-prev-two"></div>
                <div class="swiper-pagination-two"></div>
            </div>
        </div>
        <!-- 我们的行业 -->
        <div class="industy_box" ref="header">
            <div class="industy max_small_box">
                <div class="left">
                    <div class="left_small">
                        <div class="title">
                            <p class="text"><span>您</span>的行业</p>
                        </div>
                        <div class="desc">
                            <p>INDUSTRY</p>
                        </div>
                        <div class="content" >
                            <p class="title" v-if="industyList" data-aos="fade-up">{{industyList[industyListIndex].name}}</p>
                            <p class="desc" v-if="industyList" data-aos="fade-up">{{industyList[industyListIndex].intro}}</p>
                        </div>
                        <!-- <router-link v-if="industyList" :to="`/industry/${industyList[industyListIndex].third_navigation_id}${industyList[industyListIndex].page_id}/${industyList[industyListIndex].second_navigation_id}/${industyListIndex}`">
                            <p class="more" data-aos="fade-up">more</p>
                        </router-link> -->
                        <a v-if="industyList" @click="handleGtoServe(industyList[industyListIndex].page_id, industyList[industyListIndex].second_navigation_id, industyList[industyListIndex].third_navigation_id)">
                            <p class="more" data-aos="fade-up">查看更多</p>
                        </a>
                    </div>
                </div>
                <div class="right" v-if="industyList">
                    <div class="swiper-industy" >
                        <div class="swiper-wrapper">
                            <router-link class="swiper-slide" v-for="(item, index) in industyList" :key="index" :to="`/industry/${item.third_navigation_id}${item.page_id}/${item.second_navigation_id}/${index}`">
                                <img v-if="item.image!=''" :src="imgUrl + item.image" alt="" />
                                <img v-else src="@/assets/images/logo.png" alt="" />
                                <p >{{item.name}}</p>
                            </router-link>
                        </div>
                        <div class="swiper-button-next"  @mouseover="handleNext"  @mouseleave="leaveNext"></div>
                        <div class="swiper-button-prev" @mouseover="handlePrev" @mouseleave="leavePrev"></div>
                        <div class="swiper-button-next swiper-button-next-two"  ></div>
                        <div class="swiper-button-prev swiper-button-prev-two" ></div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 我们的服务 -->
        <div class="service_box">
            <div class="service max_small_box">
                <div class="left">
                    <div class="left_small">
                        <div class="title">
                            <p class="text"><span>我</span>们的服务</p>
                        </div>
                        <div class="desc">
                            <p>SERVICE</p>
                        </div>
                        <div class="content">
                            <ul class="clearfix " v-if="serviceList">
                                <!-- <li v-for="(item, index) in industyList[industyListIndex].service.slice(0,5) " :key="index" @click="handleChangeService(index)" data-aos="fade-up"> -->
                                <li v-for="(item, index) in serviceList" :key="index" data-aos="fade-up">
                                    <router-link :to="`/industry/${item.third_navigation_id}${item.page_id}/${item.second_navigation_id}/${index}`">
                                        <p>{{item.name}}</p>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                        <!-- <router-link v-if="industyList" :to="`/industry/${industyList[industyListIndex].third_navigation_id}${industyList[industyListIndex].page_id}/${industyList[industyListIndex].second_navigation_id}/${industyListIndex}`">
                            <p class="more">more</p>
                        </router-link> -->
                        <!-- <a v-if="industyList" @click="handleGtoServe(industyList[industyListIndex].page_id, industyList[industyListIndex].second_navigation_id, industyList[industyListIndex].third_navigation_id)">
                            <p class="more" data-aos="fade-up">more</p>
                        </a> -->
                    </div>
                </div>
                <div class="right" v-if="serviceList">
                      <div class="swiper-service">
                        <div class="swiper-wrapper">
                            <router-link class="swiper-slide" :to="`/industry/${item.third_navigation_id}${item.page_id}/${item.second_navigation_id}/${index}`" v-for="(item, index) in serviceList" :key="index">
                                <div class="top">
                                    <img v-if="item.image !=''" :src="imgUrl + item.image" alt="" />
                                    <img v-else src="@/assets/images/service_banner.png" alt="" />
                                    <!-- Add Arrows -->
                                </div>
                                <div class="under" >
                                    <p class="title" data-aos="fade-up">{{item.name}}</p>
                                    <p class="desc"  data-aos="fade-up">{{item.intro}}</p>
                                </div>
                            </router-link>
                        </div>
                        <div class="swiper-button-next-service"></div>
                        <div class="swiper-button-prev-service"></div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 新闻资讯 -->
        <div class="news_box">
            <div class="news">
                <div class="top ">
                    <div class="first max_small_box">
                        <p class="small_title">新闻资讯</p>
                        <p class="title"><span>新</span>闻资讯</p>
                        <div class="nav_list">
                            <p v-for="(item, index) in newsTypes" :key="index" :class="newsTypesIndex == index ? 'active' : '' " @click="handleChangeNewsType(index)">{{item.name}}</p>
                            <router-link v-if="newsTypes" :to="`${newsTypes[0].page_id}/${newsTypes[0].toLink}/${newsTypes[0].id}${newsTypes[0].page_id}`">
                                <p class="iconfont arrow">&#xe622;</p>
                            </router-link>
                        </div>
                    </div>
                    <div class="line max_big_box"></div>
                    <p class="desc max_small_box">NEWS</p>
                </div>
                <div class="under max_small_box">
                    <router-link :to="`/news/news_detail/${item.id}`" class="item" v-for="(item, index) in newsList" :key="index" data-aos="flip-left">
                        <div class="image_box">
                            <img v-if="item.image == '' || item.image == null" src="@/assets/images/a900a965ed5d1453b71abb6d303d0ab.jpg" alt="" />
                            <img v-else :src="imgUrl + item.image" alt="" />
                        </div>
                        <div class="content">
                            <p class="title">{{item.title}}</p>
                            <p class="desc">{{item.intro}}</p>
                        </div>
                        <div class="releasetime">{{item.releasetime}}</div>
                    </router-link>
                </div>
                <div class="more_box max_small_box">
                    <router-link v-if="newsTypes" :to="`${newsTypes[newsTypesIndex].page_id}/${newsTypes[newsTypesIndex].toLink}/${newsTypes[newsTypesIndex].id}${newsTypes[newsTypesIndex].page_id}`">
                        <p class="more">查看更多</p>
                    </router-link>
                </div>
            </div>
        </div>
        <!-- 查询报告 -->
        <div class="report_box" id="report" ref="report">
            <div class="report">
                <div class="top ">
                    <div class="first max_small_box">
                        <p class="title"><span>查</span>询报告</p>
                    </div>
                    <div class="line max_big_box"></div>
                    <p class="desc max_small_box">REPORT</p>
                </div>
                <div class="under max_small_box" data-aos="fade-down">
                    <div class="first item">
                        <select v-model="reportValue" >
                            <option v-for="item in reportList" :value="item.id" :key="item.name">{{ item.name }}</option>
                        </select>
                    </div>
                    <div class="second item" v-if="reportValue==0">
                        <input placeholder="单号" v-model="WTDH" />
                    </div>
                    <div class="second item" v-if="reportValue==1 || reportValue==2 || reportValue==3 || reportValue==4">
                        <input placeholder="证书号" v-model="WTDH2" />
                    </div>
                    <div class="second item" v-if="reportValue==5">
                        <input placeholder="报告编号" v-model="BGBH" />
                    </div>
                    <div class="third item" v-if="reportValue==0">
                        <input placeholder="验证码（请联系业务对接人获取）" v-model="YZM" />
                    </div>
                    <div class="third item" v-if="reportValue==5">
                        <input placeholder="防伪码" v-model="FWM" />
                    </div>
                    <div class="fourth item">
                        <button @click="submit">提交</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onBeforeMount, onMounted, onUnmounted, reactive, toRefs, ref, watchEffect } from "vue";
import { useRoute, useRouter } from 'vue-router'
import Swiper, {   
  A11y,
  Autoplay,
  Controller,
  EffectCoverflow,
  EffectCube,
  EffectFade,
  EffectFlip,
  HashNavigation,
  History,
  Keyboard,
  Lazy,
  Mousewheel,
  Navigation,
  Pagination,
  Parallax,
  Scrollbar,
  Thumbs,
  Virtual,
  Zoom, } from "swiper";
Swiper.use([Autoplay, EffectCoverflow, EffectCube, Pagination, Navigation]);
import { get, post } from '../../utils/request';
import * as api from '../../utils/api';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import FloatingFrame from '../../components/floatingFrame/FloatingFrame';

//轮播图逻辑
const bannerEffect = () => {
    const list = ref('')

    const mobile = ref('')  //手机端轮播图

    const bannerSwiper = async () =>{
        try{
            const result = await post('/carousel/get_carousel', {page_id: '/',remarks:'pc'})
            // console.log('首页轮播图', result)
            if(result.code == 1){
                list.value = result.data.content
                setTimeout(() => {
                    let swiper = new Swiper(".swiper-container", {
                        pagination: {
                            el: ".swiper-pagination",
                            clickable :true,
                        },
                        autoplay: {
                            delay: 5000,
                            stopOnLastSlide: false,
                            disableOnInteraction: false,
                        },
                        navigation: {
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        },
                        on: {
                            navigationShow: function () {
                                // console.log("按钮显示了");
                            },
                        },
                        loop : true,
                    });
                    //如果你在swiper初始化后才决定使用clickable，可以这样设置
                    swiper.params.pagination.clickable = true ;
                    //此外还需要重新初始化pagination
                    swiper.pagination.destroy()
                    swiper.pagination.init()
                    swiper.pagination.bullets.eq(0).addClass('swiper-pagination-bullet-active');
                },1)
                
            }else{
                // console.log('请求失败')
            }
        }catch(e) {
        
        }

        try{
            const result = await post('/carousel/get_carousel', {page_id: '/',remarks:'mobile'})
            // console.log('首页轮播图', result)
            if(result.code == 1){
                mobile.value = result.data.content
                setTimeout(() => {
                    let swiper = new Swiper(".swiper-container-two", {
                        autoplay: {
                            delay: 5000,
                            stopOnLastSlide: false,
                            disableOnInteraction: false,
                        },
                        navigation: {
                            nextEl: '.swiper-button-next-two',
                            prevEl: '.swiper-button-prev-two',
                        },
                        on: {
                            navigationShow: function () {
                                // console.log("按钮显示了");
                            },
                        },
                        loop : true,
                    });
                    //如果你在swiper初始化后才决定使用clickable，可以这样设置
                    swiper.params.pagination.clickable = true ;
                    //此外还需要重新初始化pagination
                    swiper.pagination.destroy()
                    swiper.pagination.init()
                },1)
                
            }else{
                // console.log('请求失败')
            }
        }catch(e) {
        
        }
    }

    //轮播图跳转
    const gotoPage = (link) => {
        if(link==='' || link===null || link===undefined){

        }else{
            // console.log(link)
            window.location.href=link;
        }
    }

    return { list, mobile, bannerSwiper, gotoPage }
}

//我们的行业逻辑
const industyEffect = (router) => {
    //首页行业与服务列表
    const industyList = ref('')

    //行业列表选中下标
    const industyListIndex = ref(0)

    //服务列表选中下标
    const serviceListIndex = ref(0)

    const industrySwiper = ref()

    //获取首页行业
    const industySwiper = async () => {
        try{
            const result = await get('/index/get_service_industry', {})
            // console.log('首页行业与服务', result)
            if(result.code == 1){
                industyList.value = result.data
                setTimeout(() => {
                    industrySwiper.value = new Swiper(".swiper-industy", {
                        slidesPerView: 3,
                        spaceBetween: 30,
                        centeredSlides: false,
                        loop: true,
                        // autoplay: true,
                        autoplayDisableOnInteraction : false,
                        on: {
                            slideChangeTransitionEnd: function(){
                                industyListIndex.value = this.realIndex
                                serviceListIndex.value = 0
                            },
                        },
                        navigation: {
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        },
                    });
                },1)
                
            }else{
                // console.log('请求失败')
            }
        }catch(e) {
        
        }
    }

    //点击更多
    const handleGtoServe = async (page_id, second_id, third_id) => {
        let postData = {
            page_id,
            second_navigation_id: second_id
        }
         await post('/nav/get_third_nav', postData).then((res) => {
             // console.log(res)
             for(let i=0; i<res.data.length;i++){
                 if(third_id == res.data[i].id){
                    // console.log(i)
                    // :to="`/industry/third_id page_id/second_id/i"
                    router.push({ path: `/industry/${third_id}${page_id}/${second_id}/${i}` })
                 }
             }
         })
    } 

    //更换服务下标
    const handleChangeService = (index) => {
        serviceListIndex.value = index
    }
    
    return{ industySwiper, industyList, industyListIndex, serviceListIndex, handleChangeService, handleGtoServe, industrySwiper }
}

//我们的服务逻辑
const serviceEffect = (router) => {

    //服务列表
    const serviceList = ref()
    
    //获取首页服务
    const serviceSwiper = async () => {
        await get('/index/get_service', {}).then((res) => {
            // console.log('首页服务', res)
            serviceList.value = res.data
            setTimeout(() => {
                new Swiper('.swiper-service', {
                    autoplay: {
                        delay: 5000,
                        stopOnLastSlide: false,
                        disableOnInteraction: false,
                    },
                    navigation: {
                        nextEl: '.swiper-button-next-service',
                        prevEl: '.swiper-button-prev-service',
                    },
                });
            },1)
        })
    }

    return { serviceSwiper, serviceList }
}


//新闻资讯逻辑
const newsEffect = () => {
    //新闻分类
    const newsTypes = ref('')
    //新闻分类下标
    const newsTypesIndex = ref(0)
    //新闻列表
    const newsList = ref()

    //请求新闻分类
    const getNewsType = async () => {
        try{
            const result = await post('/index/get_news_type', {page_id: '/news'})
            // console.log('首页新闻分类', result)
            if(result.code == 1){
                let list = []
                list.push(result.data[1])
                list.push(result.data[0])
                list.push(result.data[2])
                // newsTypes.value = result.data.slice(0,3)
                newsTypes.value = list
                getNewsList()
            }else{
                // console.log('请求失败')
            }
        }catch(e) {
        
        }
    }

    //更换新闻分类
    const handleChangeNewsType = (index) => {
        newsTypesIndex.value = index
        getNewsList()
    }

    //请求新闻列表
    const getNewsList = async () => {
        let postData = {
            second_navigation_id: newsTypes.value[newsTypesIndex.value].id
        }
        try{
            const result = await post('/index/get_news', postData)
            // console.log('首页新闻列表', result)
            if(result.code == 1){
                for(let i=0;i<result.data.length;i++){
                    result.data[i].releasetime = result.data[i].releasetime.split(' ')[0]
                }
                newsList.value = result.data.slice(0,4)
            }else{
                // console.log('请求失败')
            }
        }catch(e) {
        
        }
    }
    
    return { newsList, newsTypes, handleChangeNewsType, newsTypesIndex, getNewsType }
}

//查询报告逻辑
const reportEffect = (router) => {
    const reporSelect = reactive({
        
        reportList: [
            // {
            //     id: 5,
            //     name: '报告防伪查询'
            // },
            // {
            //     id: 0,
            //     name: '检验报告查询'
            // },
            // {
            //     id: 1,
            //     name: '金银珠宝证书查询'
            // },
            // {
            //     id: 2,
            //     name: '红木家具质量证书查询'
            // },
            // {
            //     id: 3,
            //     name: '电子电器证书查询'
            // },
            // {
            //     id: 4,
            //     name: '培训证书查询'
            // },
        ],
        reportValue: 99,  //选中框的值
        WTDH: '',  //单号
        WTDH2: '',  //证书号
        YZM: '', //验证码
        BGBH: '', //报告编号
        FWM: '', //防伪码
        wtdhlink: '',// 单号查询链接
        bgbhlink: '',// 报告防伪查询链接
    })
    //请求链接
    const getLink = async () => {
        try{
            const result = await get('/index/get_Link', {})
            if(result.code == 1){
                for(let i=0;i<result.data.length;i++){
                    if(result.data[i].name==='wtdhlink'){
                        wtdhlink.value = result.data[i].value
                    }
                    if(result.data[i].name==='bgbhlink'){
                        bgbhlink.value = result.data[i].value
                    }
                }
            }else{
                // console.log('请求失败')
            }
        }catch(e) {
        
        }
    }
    
    const getReportList = async () => {
        try{
            const result = await get('/index/get_report', {})
            if(result.code == 1){
                let list = result.data
                for(let i=0;i<result.data.length;i++){
                    list[i].id = parseInt(result.data[i].tip)
                    list[i].name = result.data[i].title
                }
                reportList.value = list
                reportValue.value = list[0].id
            }
        }catch(e) {
        
        }
    }

    //提交
    const submit = () => {
        // if(reporSelect.WTDH==""){
		// 	alert("请输入委托单号");
		// 	return false;
		// }
		// if(reporSelect.BGBH==""){
		// 	alert("请输入报告编号");
		// 	return false;
		// }

		if(reporSelect.reportValue==0){
            // var url = "http://www.gddqt.com/QTIMSWeb/JYBGCX.aspx?WTDH="+reporSelect.WTDH+"&YZM="+reporSelect.YZM;
            
            var url = reporSelect.wtdhlink+"?WTDH="+reporSelect.WTDH+"&YZM="+reporSelect.YZM;
            window.open(url,"","width=800,height=600,top=100, left=100, toolbar=no, menubar=no, scrollbars=yes, resizable=yes,location=no, status=no");
		}
		if(reporSelect.reportValue==1){
            if(reporSelect.WTDH2 ==''){
                alert("请输入证书号");
            }else{
                router.push({ path: `/reoprt_detail/${reporSelect.WTDH2}` })
            }
		}
		if(reporSelect.reportValue==2){
		    if(reporSelect.WTDH2 ==''){
                alert("请输入证书号");
            }else{
                router.push({ path: `/reoprt_detail/${reporSelect.WTDH2}` })
            }
		}
		if(reporSelect.reportValue==3){
		    if(reporSelect.WTDH2 ==''){
                alert("请输入证书号");
            }else{
                router.push({ path: `/reoprt_detail/${reporSelect.WTDH2}` })
            }
		}
		if(reporSelect.reportValue==4){
           if(reporSelect.WTDH2 ==''){
                alert("请输入证书号");
            }else{
                router.push({ path: `/reoprt_detail/${reporSelect.WTDH2}` })
            }
		}
		if(reporSelect.reportValue==5){
		    // var url = "http://www.gddqt.com/QTIMSWeb/BGFWCX.aspx?BGBH="+reporSelect.BGBH+"&FWM="+reporSelect.FWM;
		    var url = reporSelect.bgbhlink + "?BGBH="+reporSelect.BGBH+"&FWM="+reporSelect.FWM;
		    window.open(url,"","width=800,height=600,top=100, left=100, toolbar=no, menubar=no, scrollbars=yes, resizable=yes,location=no, status=no");
		}
    }

    const { reportList, reportValue, WTDH, WTDH2, YZM, BGBH, FWM, wtdhlink, bgbhlink} = toRefs(reporSelect)

    return { reportList, reportValue, WTDH, WTDH2, YZM, BGBH, FWM, submit, getReportList, getLink }
}

//页面滚动处理函数
const ScrollEffect = () => {

    const isShow = ref('')

    const handleScroll = (height) => {
        
        if(document.documentElement.scrollTop >= height){
            isShow.value = 1
        }else{
            isShow.value = 2
        }
    }

    return { handleScroll, isShow }
}


export default{
    name: 'Home',
    components: { Header, Footer, FloatingFrame },
    setup() {
        const router = useRouter()
        const route = useRoute()
        //轮播图逻辑
        const { list, mobile, bannerSwiper, gotoPage } = bannerEffect()

        //我们的行业逻辑
        const { 
                industySwiper, 
                industyList, 
                industyListIndex, 
                serviceListIndex, 
                handleChangeService ,
                handleGtoServe,
                industrySwiper
            } = industyEffect(router)

        //我们的服务逻辑
        const { serviceSwiper, serviceList } = serviceEffect()

        //新闻资讯逻辑
        const { 
                newsList, 
                newsTypes, 
                handleChangeNewsType,
                newsTypesIndex, 
                getNewsType ,
            } = newsEffect()

        //查询报告逻辑
        const { 
                reportList, 
                reportValue, 
                WTDH, 
                WTDH2, 
                YZM, 
                BGBH, 
                FWM,
                wtdhlink,
                bgbhlink,
                submit,
                getReportList,
                getLink
            } = reportEffect(router)

        //页面滚动处理函数
        const { handleScroll, isShow } = ScrollEffect()
        
        //获取我的行业到顶部的距离
        const header = ref(null)

        const report = ref(null)

        onBeforeMount(() => {
            document.querySelector('body').setAttribute('style', 'background-color:#F8F8F8')
        })

        onUnmounted(() => {
            document.querySelector('body').setAttribute('style', 'background-color:#fff')
            window.removeEventListener("scroll", function () {
                handleScroll();
            })
        })


        onMounted(() => {
            document.documentElement.scrollTop = document.body.scrollTop =0;
            window.addEventListener('scroll', function () {
                if(header.value){
                    handleScroll(header.value.offsetTop);
                }
            })
            // report.value.scrollTop(6000)
        });

        const nextInterval = ref()

        const prevInterval = ref()

        const handleNext = () => {
            industrySwiper.value.slideNext()
            nextInterval.value = setInterval(() => {
                industrySwiper.value.slideNext()
            }, 2000)    
            
        }

        const leaveNext = () => {
            clearInterval(nextInterval.value)
        }

        const handlePrev = () => {
            industrySwiper.value.slidePrev()
            prevInterval.value = setInterval(() => {
                industrySwiper.value.slidePrev()
            }, 2000)
        }

        const leavePrev = () => {
            clearInterval(prevInterval.value)
        }


        bannerSwiper()  //轮播图
        industySwiper()  //我的行业轮播图
        serviceSwiper()  //我们的服务
        getNewsType()  //新闻分类
        getReportList()  // 查询报告
        getLink()  // 查询链接

        const { imgUrl }  = api

        return{ 
                //轮播图
                list, 
                mobile,
                gotoPage,

                //我的行业
                industyList, 
                industyListIndex, 
                serviceListIndex, 
                handleChangeService, 
                handleGtoServe,
                handleNext,
                leaveNext,
                handlePrev,
                leavePrev,

                // 我们的服务
                serviceList,

                //新闻
                newsList, 
                newsTypes,
                handleChangeNewsType, 
                newsTypesIndex,

                reportList, 
                reportValue, 
                WTDH, 
                WTDH2, 
                YZM, 
                BGBH, 
                FWM,
                wtdhlink,
                bgbhlink,
                submit,
                getReportList,
                getLink,

                header, 
                report,
                isShow, 
                imgUrl ,
                
            }
    }
}
</script>

<style lang="scss" scoped>
@import '../../style/viriables.scss';
@import '../../style/mixin.scss';
/* 轮播图 */
.banner{
    margin-top: 111px;
    display: block;
    @media screen and (max-width: 1200px){
        margin-top: 86px;
        display: none;
    }
    .swiper-container {
      width: 100%;
      height: 100%;
    }
    .swiper-slide {
      position: relative;
      text-align: center;
      font-size: 18px;
      background: #fff;
      /* Center slide text vertically */
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      cursor: pointer;
      @media screen and (max-width: 900px){
        height: 180px;
     }
        img{
            width: 100%;
            @media screen and (max-width: 900px){
                height: 100%;
                object-fit: cover;
            }
        }
        .content_box{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            .content{
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                @media screen and (max-width: 1200px){
                    padding: 0 30px;
                }
                .title{
                    color: #fff;
                    font-size: 40px;
                    font-weight: bold;
                    @media screen and (max-width: 900px){
                        font-size: 16px;
                    }
                }
                .desc{
                    color: #fff;
                    font-size: 32px;
                    margin-top: 30px;
                    @media screen and (max-width: 900px){
                        display: none;
                    }
                }
                .more{
                    font-size: 20px;
                    color: #fff;
                    padding: 10px 30px;
                    border: 2px solid #fff;
                    margin-top: 30px;
                    cursor: pointer;
                    @media screen and (max-width: 900px){
                        display: none;
                    }
                }
            }
        }
    }
    .swiper-button-next{
        top: 96.5%;
        right: 45%;
        z-index: 99;
        font-weight: bold;
        color: #fff;
        &:after{
            font-size: 20px;
        }
        @media screen and (max-width: 1200px){
            display: none;
        }
    }
    .swiper-button-prev{
        top: 96.5%;
        left: 45%;
        z-index: 99;
        font-weight: bold;
        color: #fff;
        &:after{
            font-size: 20px;
        }
        @media screen and (max-width: 1200px){
            display: none;
        }
    }
    
    .swiper-pagination{
        @media screen and (max-width: 900px){
            display: none;
        }
        
    }
}
.banner_two{
    display: none;
    @media screen and (max-width: 1200px){
        margin-top: 86px;
        display: block;
        overflow: hidden;
    }
    .swiper-container-two {
      width: 100%;
      height: 100%;
    }
    .swiper-slide {
      position: relative;
      text-align: center;
      font-size: 18px;
      background: #fff;
      /* Center slide text vertically */
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      cursor: pointer;
      @media screen and (max-width: 900px){
        height: 180px;
     }
        img{
            width: 100%;
            @media screen and (max-width: 900px){
                height: 100%;
                object-fit: cover;
            }
        }
        .content_box{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            .content{
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                @media screen and (max-width: 1200px){
                    padding: 0 30px;
                }
                .title{
                    color: #fff;
                    font-size: 40px;
                    font-weight: bold;
                    @media screen and (max-width: 900px){
                        font-size: 16px;
                    }
                }
                .desc{
                    color: #fff;
                    font-size: 32px;
                    margin-top: 30px;
                    @media screen and (max-width: 900px){
                        display: none;
                    }
                }
                .more{
                    font-size: 20px;
                    color: #fff;
                    padding: 10px 30px;
                    border: 2px solid #fff;
                    margin-top: 30px;
                    cursor: pointer;
                    @media screen and (max-width: 900px){
                        display: none;
                    }
                }
            }
        }
    }
    .swiper-button-next-two{
        top: 96.5%;
        right: 45%;
        z-index: 99;
        font-weight: bold;
        color: #fff;
        &:after{
            font-size: 20px;
        }
        @media screen and (max-width: 1200px){
            display: none;
        }
    }
    .swiper-button-prev-two{
        top: 96.5%;
        left: 45%;
        z-index: 99;
        font-weight: bold;
        color: #fff;
        &:after{
            font-size: 20px;
        }
        @media screen and (max-width: 1200px){
            display: none;
        }
    }
    
    .swiper-pagination-two{
        @media screen and (max-width: 900px){
            display: none;
        }
        
    }
}
/* 我们的行业 */
.industy_box{
    .industy{
        position: relative;
        .left{
            width: 50%;
            background: $content-backgroundcolor;
            padding-bottom: 4%;
            @media screen and (max-width: 900px){
                width: 100%;
            }
            .left_small{
                .title{
                    .text{
                        color: #fff;
                        padding: 0;
                        font-size: 20px;
                        /* padding-left: $home-industy-padding-left; */
                        margin-left: $home-industy-padding-left;
                        /* padding-top: 40px; */
                        padding-top: 30px;
                        padding-bottom: 8px;
                        border-bottom: 1px solid #BBBBBB;
                        @media screen and (max-width: 1200px){
                            /* padding-left: 30px; */
                            margin-left: 8%;
                        }
                        @media screen and (max-width: 900px){
                            font-size: .16rem;
                        }
                        span{
                            font-size: 40px;
                            @media screen and (max-width: 900px){
                                font-size: .30rem;
                            }
                        }
                    }
                }
                .desc{
                    p{
                        color: #fff;
                        padding: 8px 0 0 $home-industy-padding-left;
                        /* font-size: 20px; */
                        font-size: 14px;
                        @media screen and (max-width: 1200px){
                            padding-left: 8%;
                        }
                        @media screen and (max-width: 900px){
                            font-size: .16rem;
                        }
                    }
                }
                .content{
                    padding-top: 10%;
                    padding-left: $home-industy-padding-left;
                    @media screen and (max-width: 1440px){
                        /* padding-top: 8%; */
                        padding-top: 4%;
                    }
                    @media screen and (max-width: 1200px){
                        padding-left: 8%;
                        padding-top: 8%;
                    }
                    .title{
                        font-size: 20px;
                        color: #fff;
                        @media screen and (max-width: 900px){
                            font-size: .16rem;
                        }
                    }
                    .desc{
                        font-size: 14px;
                        color: #fff;
                        height: 62px;
                        margin-top: 5%;
                        padding-right: 30%;
                        margin-bottom: 26px;
                        line-height: 20px;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                        @media screen and (max-width: 900px){
                            font-size: .14rem;
                        }
                    }
                }
                .more{
                    margin-left: $home-industy-padding-left;
                    @include more;
                    @media screen and (max-width: 1200px){
                        margin-left: 8%;
                    }
                    @media screen and (max-width: 900px){
                        padding: .01rem .2rem;
                    }
                    &:hover{
                        background: #fff;
                        color: $content-backgroundcolor;
                    }
                }
            }
        }
        .right{
            /* width: 60%; */
            width: 60%;
            margin-right: 0%;
            position: absolute;
            top: 20%;
            right: 0;
            @media screen and (max-width: 900px){
                position: static;
                width: 90%;
                margin: 0 auto
            }
            .swiper-industy {
                width: 100%;
                height: 100%;
                overflow: hidden;
                /* padding: 20% 0; */
                padding: 6% 0;
                @media screen and (max-width: 900px){
                    padding: 8% 0;
                }
                .swiper-slide {
                    /* width: 163px!important; */
                    text-align: center;
                    font-size: 18px;
                    background: #fff;
                    /* Center slide text vertically */
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: -webkit-flex;
                    display: flex;
                    flex-direction: column;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    -webkit-justify-content: center;
                    justify-content: center;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    -webkit-align-items: center;
                    align-items: center;
                    transition: 300ms;
                    transform: scale(0.8);
                    /* @media screen and (max-width: 600px){
                        height: 150px;
                    } */
                    img{
                        width: 100%;
                        height: 160px;
                        object-fit: cover;
                        @media screen and (max-width: 1200px){
                            height: 160px;
                        }
                        @media screen and (max-width: 750px){
                            height: 100px;
                        }
                        @media screen and (max-width: 500px){
                            height: 80px;
                        }
                    }
                    p{
                        color: rgba(0, 0, 0, 1);
                        font-size: 16px;
                        padding: 14px 0;
                    }
                }
                .swiper-slide-active,.swiper-slide-duplicate-active{
                    transform: scale(1);
                    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
                }
                .swiper-button-prev{
                    left: -12%;
                    width: 120px;
                    top: 7%;
                    height: 257px;
                    transform: scale(.5);
                    font-weight: bold;
                    color: #777777;
                    opacity: 0;
                    @media screen and (max-width: 900px){
                        display: none;
                    }
                }
                .swiper-button-next{
                    right: -9%;
                    width: 120px;
                    top: 7%;
                    height: 257px;
                    /* top: 80%; */
                    transform: scale(.5);
                    font-weight: bold;
                    color: #777777;
                    opacity: 0;
                    @media screen and (max-width: 900px){
                        display: none;
                    }
                }
                .swiper-button-prev-two{
                    left: 45%;
                    width: auto;
                    height: auto;
                    /* top: 80%; */
                    top: 95%;
                    transform: scale(.5);
                    font-weight: bold;
                    color: #777777;
                    opacity: 1;
                    @media screen and (max-width: 900px){
                        display: none;
                    }
                }
                .swiper-button-next-two{
                    right: 35%;
                    top: 95%;
                    width: auto;
                    height: auto;
                    /* top: 80%; */
                    transform: scale(.5);
                    font-weight: bold;
                    color: #777777;
                    opacity: 1;
                    @media screen and (max-width: 900px){
                        display: none;
                    }
                }
            }
        }
    }
}
/* 我们的服务 */
.service_box{
    .service{
        display: flex;
        @media screen and (max-width: 900px){
            display: block;
        }
        .left{
            width: 50%;
            background: $content-backgroundcolor;
            padding-bottom: 5%;
            @media screen and (max-width: 900px){
                width: 100%;
            }
            .left_small{
                .title{
                    .text{
                        color: #fff;
                        padding: 0;
                        font-size: 20px;
                        /* padding-left: $home-industy-padding-left; */
                        margin-left: $home-industy-padding-left;
                        padding-top: 40px;
                        padding-bottom: 8px;
                        border-bottom: 1px solid #BBBBBB;
                        @media screen and (max-width: 1200px){
                            /* padding-left: 30px; */
                            margin-left: 8%;
                        }
                        @media screen and (max-width: 900px){
                            font-size: .16rem;
                        }
                        span{
                            font-size: 40px;
                            @media screen and (max-width: 900px){
                                font-size: .30rem;
                            }
                        }
                    }
                }
                .desc{
                    p{
                        color: #fff;
                        padding: 8px 0 0 $home-industy-padding-left;
                        /* font-size: 20px; */
                        font-size: 14px;
                        @media screen and (max-width: 1200px){
                            padding: 20px 0 0 8%;
                        }
                        @media screen and (max-width: 900px){
                            font-size: .16rem;
                        }
                    }
                }
                .content{
                    padding-top: 10%;
                    padding-left: $home-industy-padding-left;
                     @media screen and (max-width: 1440px){
                        padding-top: 8%;
                    }
                    @media screen and (max-width: 1200px){
                        padding-left: 8%;
                        padding-right: 30px;
                    }
                    ul{
                        width: 80%;
                        @media screen and (max-width: 1200px){
                            width: 100%;
                        }
                        li{
                            margin-right: 15px;
                            margin-bottom: 18px;
                            width: 45%;
                            float: left;
                            &:last-child{
                                margin-bottom: 26px;
                            }
                            p{
                                color: #fff;
                                font-size: 16px;
                                cursor: pointer;
                                line-height: 22px;
                                @media screen and (max-width: 900px){
                                    font-size: .14rem;
                                }
                            }
                        }
                    }
                }
                .more{
                    margin-left: $home-industy-padding-left;
                    @include more;
                    @media screen and (max-width: 1200px){
                        margin-left: 8%;
                    }
                    @media screen and (max-width: 900px){
                        padding: .01rem .2rem;
                    }
                    &:hover{
                        background: #fff;
                        color: $content-backgroundcolor;
                    }
                }
            }
        }
        .right{
            /* width: 50%; */
            width: 43%;
            @media screen and (max-width: 1200px){
                /* width: 100%; */
                margin-top: 100px;
            }
            @media screen and (max-width: 900px){
                width: 100%;
                margin-top: 0px;
            }
            .swiper-service {
                width: 100%;
                height: 100%;
                overflow: hidden;
                position: relative;
            }
            .swiper-slide {
                .top{
                    /* margin-top: 18%; */
                    margin-top: 8%;
                    height: 250px;
                    @media screen and (max-width: 1200px){
                        margin-top: 0;
                        height: auto;
                    }
                    img{
                        width: 100%;
                        height: 115%;
                        object-fit: cover;
                        @media screen and (max-width: 1200px){
                            height: 150px;
                        }
                    }
                }
                .under{
                    margin: 70px 60px 0 60px;
                    @media screen and (max-width: 1200px){
                        /* padding: 20px 30px 0 30px; */
                    }
                    @media screen and (max-width: 900px){
                        margin: .3rem $home-industy-padding-left 0 .3rem;
                    }
                    .title{
                        color: #333;
                        font-size: 20px;
                        font-weight: $all-font-weight;
                        @media screen and (max-width: 900px){
                            font-size: .16rem;
                        }
                    }
                    .desc{
                        margin-top: 10px;
                        color: $all-desc-color;
                        font-size: 14px;
                        line-height: 25px;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 5;
                        -webkit-box-orient: vertical;
                        @media screen and (max-width: 900px){
                            font-size: .14rem;
                            margin-top: .3rem;
                        }
                    }
                }
            }
            .swiper-button-prev-service, .swiper-button-next-service {
                position: absolute;
                top: 80%;
                width: calc(var(--swiper-navigation-size) / 44 * 27);
                height: var(--swiper-navigation-size);
                margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
                z-index: 10;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                color: var(--swiper-navigation-color, var(--swiper-theme-color));
                &:after {
                    font-family: swiper-icons;
                    font-size: var(--swiper-navigation-size);
                    text-transform: none !important;
                    letter-spacing: 0;
                    text-transform: none;
                    font-variant: initial;
                    line-height: 1;
                }
                @media screen and (max-width: 1200px){
                    top: 70%;
                }
            }
            .swiper-button-prev-service{
                left: 0px;
                right: auto;
                color: #777777;
                &:after {
                    content: "prev";
                    font-family: swiper-icons;
                    font-size: var(--swiper-navigation-size);
                    text-transform: none !important;
                    letter-spacing: 0;
                    text-transform: none;
                    font-variant: initial;
                    line-height: 1;
                    font-size: 30px;
                }
            }
            .swiper-button-next-service{
                right: 0px;
                left: auto;
                color: #777777;
                &:after {
                    content: "next";
                    font-family: swiper-icons;
                    font-size: var(--swiper-navigation-size);
                    text-transform: none !important;
                    letter-spacing: 0;
                    text-transform: none;
                    font-variant: initial;
                    line-height: 1;
                    font-size: 30px;
                }
            }
        }
    }
}
/* 新闻资讯 */
.news_box{
    margin-top: 60px;
    .news{
        .top{
            .first{
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
                @media screen and (max-width: 1200px){
                    padding: 0 30px;
                    display: block;
                }
                .small_title{
                    display: none;
                    @media screen and (max-width: 1200px){
                        display: block;
                        color: #333;
                        font-weight: $all-font-weight;
                        font-size: 20px;
                        margin-bottom: 30px;
                        text-align: center;
                    }
                }
                .title{
                    font-size: 20px;
                    @media screen and (max-width: 1200px){
                        display: none;
                    }
                    span{
                        font-size: 40px;
                    }
                }
                .nav_list{
                    p{
                        display: inline-block;
                        margin-left: 50px;
                        font-size: 16px;
                        cursor: pointer;
                        color: #777777;
                        &:hover{
                            color: $content-backgroundcolor;
                        }
                        @media screen and (max-width: 1200px){
                            margin-right: 20px;
                            margin-left: 0px;
                        }
                        @media screen and (max-width: 900px){
                            font-size: .14rem;
                        }
                    }
                    .active{
                        color: #000;
                    }
                    .arrow{
                        transform: rotate(180deg);
                        @media screen and (max-width: 1200px){
                            display: none;
                        }
                    }
                }
            }
            .line{
                margin-top: 8px;
                height: 1px;
                background: rgba(187, 187, 187, 100);
            }
            .desc{
                /* margin-top: 14px; */
                margin-top: 8px;
                /* font-size: 20px; */
                font-size: 14px;
                color: #777777;
                @media screen and (max-width: 1200px){
                    padding: 0 30px;
                    display: none;
                }
                @media screen and (max-width: 900px){
                    font-size: .16rem;
                }
            }
        }
        .under{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 50px;
            @media screen and (max-width: 1200px){
                padding: 0 30px;
            }
            @media screen and (max-width: 900px){
                display: flex;
                flex-wrap: wrap;
                flex: 2;
            }
            .item{
                width: 22%;
                background: #fff;
                cursor: pointer;
                transition: .3s all;
                color: #000;
                @media screen and (max-width: 900px){
                    width: 45%;
                    margin-bottom: 30px;
                }
                &:nth-child(4){
                    margin-right: 0%;
                }
                &:hover{
                    background: #2F509E;
                    color: #fff;
                    .image_box{
                        img{
                            transform: scale(1.2);
                        }
                    }
                }
                .image_box{
                    overflow: hidden;
                    width: 100%;
                    height: 200px;
                    @media screen and (max-width: 900px){
                        height: 100px;
                    }
                    img{
                        transition: .3s all;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .content{
                    padding: 20px;
                    height: 90px;
                    .title{
                        font-size: 20px;
                        font-weight: $all-font-weight;
                        line-height: 25px;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                        @media screen and (max-width: 900px){
                            font-size: .14rem;
                        }
                    }
                    .desc{
                        font-size: 14px;
                        line-height: 25px;
                        margin-top: 8px;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 3;
                        overflow: hidden;
                        @media screen and (max-width: 900px){
                            font-size: 14px;
                        }
                    }
                }
                .releasetime{
                    margin: 0 0 10px 20px;
                    color: gray;
                }
            }
        }
        .more_box{
            margin-top: 50px;
            display: flex;
            justify-content: center;
            @media screen and (max-width: 900px){
                margin-top: 0px;
            }
            .more{
                color: $content-backgroundcolor;
                font-size: 14px;
                padding-bottom: 10%;
                border: 1px solid $content-backgroundcolor;
                width: max-content;
                padding: 5px 40px;
                cursor: pointer;
                transition: .3s all;
                line-height: 30px;
                &:hover{
                    background: $content-backgroundcolor;
                    color: #fff;
                }
                @media screen and (max-width: 900px){
                    padding: .01rem .2rem;
                }
            }
        }
    }
}
/* 查询报告 */
.report_box{
    margin-top: 60px;
    .report{
        .top{
            .first{
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
                @media screen and (max-width: 1200px){
                    padding: 0 30px;
                }
                .title{
                    font-size: 20px;
                    @media screen and (max-width: 900px){
                        font-size: .16rem;
                    }
                    span{
                        font-size: 40px;
                        @media screen and (max-width: 900px){
                            font-size: .3rem;
                        }
                    }
                }
            }
            .line{
                margin-top: 8px;
                height: 1px;
                background: rgba(187, 187, 187, 100);
            }
            .desc{
                /* margin-top: 14px; */
                margin-top: 8px;
                /* font-size: 20px; */
                font-size: 14px;
                color: #777777;
                @media screen and (max-width: 1200px){
                    padding: 0 30px;
                }
                @media screen and (max-width: 900px){
                    font-size: .16rem;
                }
            }
        }
        .under{
            margin-top: 50px;
            margin-bottom: 50px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            @media screen and (max-width: 1200px){
                padding: 0 30px;
            }
            @media screen and (max-width: 900px){
                display: block;
                padding: 0 60px;
            }
            .item{
                width: 22%;
                @media screen and (max-width: 900px){
                    width: 100%;
                    margin: 0 auto;
                    margin-bottom: 15px;
                }
                select{
                    width: 100%;
                    outline: none;
                    border: 1px solid rgba(187, 187, 187, 100);
                    padding: 15px 10px;
                    color: #868686;
                    appearance: none;
                    -moz-appearance: none;
                    -webkit-appearance: none;
                    cursor: pointer;
                    font-size: 16px;
                    background: url("../../assets/images/jiantou.png") no-repeat scroll 230px center transparent;
                    background-size: 15px 15px;
                    background-color: #fff;
                    @media screen and (max-width: 900px){
                        background: none;
                        background-color: #fff;
                        font-size: .14rem;
                    }
                }
                input{
                    width: 100%;
                    outline: none;
                    border: 1px solid rgba(187, 187, 187, 100);
                    padding: 15px 10px;
                    color: #868686;
                    font-size: 16px;
                    @media screen and (max-width: 900px){
                        font-size: .14rem;
                    }
                }
                button{
                    width: 80%;
                    background-color: rgba(47, 80, 158, 100);
                    font-size: 16px;
                    border: none;
                    color: #fff;
                    padding: 15px 0;
                    cursor: pointer;
                    @media screen and (max-width: 900px){
                        font-size: .14rem;
                    }
                }
            }
            .fourth{
                text-align: right;
                @media screen and (max-width: 900px){
                    text-align: center;
                }
            }
        }
    }
}
</style>

<style lang="scss" >
.swiper-pagination-bullet-active{
    background: #fff;
}
</style>